
const routes :Array<CookiesRouter> = [
  {
    path: '/Reports',
    name: 'Reports',
    meta: {
      Access: 'Reports_View' as AccessFeature
    },
    component: () => import('@/Apps/Reports/views/Reports.vue'),
    children: [
      {
        path: '/Reports/Inventory',
        name: 'Inventory Reports',
        component: () => import('@/Apps/Inventory/views/Reports/InventoryReports.vue'),
        meta: {
          Access: 'Inventory_View' as AccessFeature
        },
        children: [
          {
            path: '/Reports/Inventory/Exports',
            name: 'Export Reports',
            component: () => import('@/Apps/Inventory/views/Reports/ExportsReports.vue'),
            meta: {
              Access: 'Inventory_Transfers_Read' as AccessFeature,
              ReportsDateSelector: true
            },
            children: [
              {
                path: '/Reports/Inventory/Exports/All',
                name: 'Exports Reports',
                component: () => import('@/Apps/Inventory/views/Reports/Exports/AllExportsReports.vue'),
                meta: {
                  Access: 'Inventory_Transfers_Read' as AccessFeature
                }
              },
              {
                path: '/Reports/Inventory/Exports/Items',
                name: 'Items Exports Report',
                component: () => import('@/Apps/Inventory/views/Reports/Exports/InventoryItemsExportsReports.vue'),
                meta: {
                  Access: 'Inventory_Transfers_Read' as AccessFeature
                }
              },
              {
                path: '/Reports/Inventory/Exports/Categories',
                name: 'Categories Exports Report',
                component: () => import('@/Apps/Inventory/views/Reports/Exports/InventoryCategoriesExportsReports.vue'),
                meta: {
                  Access: 'Inventory_Transfers_Read' as AccessFeature
                }
              },
              {
                path: '/Reports/Inventory/Exports/Brands',
                name: 'Brands Exports Report',
                component: () => import('@/Apps/Inventory/views/Reports/Exports/InventoryBrandsExportsReports.vue'),
                meta: {
                  Access: 'Inventory_Transfers_Read' as AccessFeature
                }
              }
            ]
          },
          {
            path: '/Reports/Inventory/Imports',
            name: 'Imports Reports',
            component: () => import('@/Apps/Inventory/views/Reports/ImportsReports.vue'),
            meta: {
              Access: 'Inventory_Transfers_Read' as AccessFeature,
              ReportsDateSelector: true
            }
          },
          {
            path: '/Reports/Inventory/ExternalImports',
            name: 'External Imports Reports',
            component: () => import('@/Apps/Inventory/views/Reports/ExternalImportsReports.vue'),
            meta: {
              Access: 'Inventory_Transfers_Read' as AccessFeature,
              ReportsDateSelector: true
            }
          },
          {
            path: '/Reports/Inventory/Items',
            name: 'Items Reports',
            component: () => import('@/Apps/Inventory/views/Reports/InventoryItemsReports.vue'),
            meta: {
              Access: 'Inventory_Transfers_Read' as AccessFeature
            },
            children: [
              {
                path: '/Reports/Inventory/Items/All',
                name: 'All Items Report',
                component: () => import('@/Apps/Inventory/views/Reports/Items/InventoryAllItemsReports.vue'),
                meta: {
                  Access: 'Inventory_Transfers_Read' as AccessFeature
                }
              },
              {
                path: '/Reports/Inventory/Items/Categories',
                name: 'Items Categories Report',
                component: () => import('@/Apps/Inventory/views/Reports/Items/InventoryCategoriesReports.vue'),
                meta: {
                  Access: 'Inventory_Transfers_Read' as AccessFeature
                }
              },
              {
                path: '/Reports/Inventory/Items/Brands',
                name: 'Items Brands Reports',
                component: () => import('@/Apps/Inventory/views/Reports/Items/InventoryBrandsReports.vue'),
                meta: {
                  Access: 'Inventory_Transfers_Read' as AccessFeature
                }
              },
              {
                path: '/Reports/Inventory/Items/AvailableItems',
                name: 'Available Items Reports',
                component: () => import('@/Apps/Inventory/views/Reports/Items/InventoryAvailableItemsReports.vue'),
                meta: {
                  Access: 'Inventory_Items_Read' as AccessFeature
                }
              },
              {
                path: '/Reports/Inventory/Items/CategoriesExports',
                name: 'Items Categories Exports Reports',
                component: () => import('@/Apps/Inventory/views/Reports/Items/InventoryCategoriesReports.vue'),
                meta: {
                  Access: 'Inventory_Transfers_Read' as AccessFeature,
                  ReportsDateSelector: true
                }
              },
              {
                path: '/Reports/Inventory/Items/BrandsExports',
                name: 'Items Brands Exports Reports',
                component: () => import('@/Apps/Inventory/views/Reports/Items/InventoryBrandsReports.vue'),
                meta: {
                  Access: 'Inventory_Transfers_Read' as AccessFeature,
                  ReportsDateSelector: true
                }
              }
            ]
          }
        ]
      }
    ]
  }
]

export const ReportsRoutes = routes
